import React from "react";
import Card from "./card";

const Articles = ({ articles }) => {
  return (
    <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
      {articles.map((article, i) => {
        return (
          <Card article={article} key={`article__left__${article.node.slug}`} />
        );
      })}
    </div>
  );
};

export default Articles;
