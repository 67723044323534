import React from "react";
import { Link } from "gatsby";
import Moment from 'react-moment';
import { GatsbyImage } from "gatsby-plugin-image";

const Card = ({ article }) => {
  return (
    <Link to={`/article/${article.node.slug}`}>
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-shrink-0">
          <GatsbyImage
            image={article.node.image.childImageSharp.gatsbyImageData}
            alt={`Hero image`}
          />
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <p className="text-sm font-medium text-indigo-600">
              {article.node.category.name}
            </p>

            <p className="text-xl font-semibold text-gray-900">
              {article.node.title}
            </p>
          </div>
          <div className="mt-6 flex items-center">
            <div className="flex-shrink-0">
              <span className="sr-only">{article.node.author.name}</span>
              {article.node.author.picture && (
                <GatsbyImage
                  image={
                    article.node.author.picture.childImageSharp.gatsbyImageData
                  }
                  alt={`Picture of ${article.node.author.name}`}
                  style={{ borderRadius: "50%" }}
                />
              )}
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                {article.node.author.name}
              </p>
              <div className="flex space-x-1 text-sm text-gray-500">
                <time dateTime="2020-03-16">
                  <Moment format="DD-MMM-YYYY">
                    {article.node.publishedAt}
                  </Moment>
                </time>
                <span aria-hidden="true">&middot;</span>
                <span>{article.node.read_min} min read</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
